import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import { SKELETON_VARIANT_RECTANGULAR } from 'styles/theme'
import { RefObject, useRef } from 'react'
import { LogoComponent, LogoColorType } from 'components/Logo/LogoComponent'
import { useStyles } from './HeroContainer.styles'
import { HeroImage } from './HeroImage'
import { HeroText } from './HeroText'

export type HeroContainerProps = {
    headerMediaUrl: string
    headlineText?: string
    heroRef?: RefObject<HTMLDivElement>
    isMobileWidth: boolean
    isVideo?: boolean
    isScrolled: boolean
    scrollElementToTop: (elementId: string) => void
    showText: boolean
}

export const HeroContainer = ({
    headerMediaUrl,
    headlineText,
    heroRef,
    isMobileWidth,
    isScrolled,
    isVideo,
    scrollElementToTop,
    showText
}: HeroContainerProps): JSX.Element => {
    const { classes } = useStyles()
    const heroLogoRef = useRef(null)
    return (
        <div
            className={classes.heroContainer}
            ref={heroRef}
            style={{
                height: Boolean(isMobileWidth) ? '88vh' : 'calc(100vh - 55vh)',
                minHeight: '500px',
                overflow: 'hidden'
            }}
        >
            {headerMediaUrl === '' ? (
                <Skeleton
                    className={classes.elementToStretch}
                    height="100%"
                    variant={SKELETON_VARIANT_RECTANGULAR}
                    width="100%"
                />
            ) : (
                <HeroImage
                    headerMediaUrl={headerMediaUrl}
                    isMobileWidth={isMobileWidth}
                    isVideo={isVideo}
                />
            )}
            {showText && headlineText && (
                <HeroText
                    headlineText={headlineText}
                    isMobileWidth={isMobileWidth}
                />
            )}
            {!Boolean(isScrolled) && (
                <div
                    style={{ margin: '0 auto', width: '100%' }}
                    ref={heroLogoRef}
                >
                    <Button
                        aria-label="Scroll to Events Container"
                        disableRipple
                        className={classes.logoButtonContainer}
                        onClick={() => scrollElementToTop('events-container')}
                    >
                        <LogoComponent
                            color={LogoColorType.WHITE}
                            height="60px"
                            width="125px"
                        />
                    </Button>
                </div>
            )}
        </div>
    )
}
