import { useEffect, useMemo, useState } from 'react'
import { EventCard } from 'components/EventCard/EventCardComponent'
import { EventJSON } from '../../../events-service/src/models/event'
import { getImage } from '../util/getEventHeaderImageByDevice'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import { HeroContainer } from 'components/HeroContainer/HeroContainer'
import {
    BORDER_RADIUS,
    SIZE_16,
    SIZE_20,
    SIZE_24,
    SKELETON_VARIANT_RECTANGULAR,
    theme
} from 'styles/theme'
import { TourJSON } from '../../../events-service/src/models/tour'
import { useHome } from 'hooks/useHome'
import { useNav } from 'hooks/useNav'
import { useWindowWidth } from 'hooks/useWidth'
import getConfig from 'next/config'
import Head from 'next/head'
import type { NextPage } from 'next'
import { APP_SETTINGS_QUERY } from 'graphQL/home/getSettingsQuery.graphql'
import { eventsWithoutJwtClient } from 'apollo-client'
import { LoadingMore } from 'components/LoadingMore'
import { dateRange } from 'util/dates'
import {
    googleAnalyticsEvent,
    GoogleAnalyticsEventActionType
} from 'components/Google/GoogleAnalytics'
import { getDeviceFromUserAgent } from 'util/parse'
import HeaderImage from '../../../events-service/src/models/headerImage'

const { publicRuntimeConfig } = getConfig()

export async function getServerSideProps(context: {
    res: { setHeader: (k: string, v: string) => void }
    req: any
}) {
    try {
        context.res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59')
        const subDomain = publicRuntimeConfig.NEXT_PUBLIC_SUBDOMAIN
        let currentDomainForCors = 'https://vibee.com'
        if (subDomain) {
            currentDomainForCors = `https://${subDomain}.vibee.com`
        }
        let userAgent = context.req.headers['user-agent'] || ''
        const { data } = await eventsWithoutJwtClient.query({
            query: APP_SETTINGS_QUERY,
            fetchPolicy: 'no-cache'
        })
        const settingsData = data.getSettings
        const { isMobile } = getDeviceFromUserAgent(userAgent)

        const setMediaUrls = (settingsData: {
            headerVideos: HeaderImage[]
            headerImage: HeaderImage[]
        }) => {
            let headerVideos = { desktopUrl: '', mobileUrl: '' }
            let headerImages = { desktopUrl: '', mobileUrl: '' }

            const { headerVideos: settingsHeaderVideos, headerImage: settingsHeaderImage } =
                settingsData

            if (settingsData.headerVideos.length) {
                settingsData.headerVideos.forEach((video) => {
                    if (video.size === 'desktop') {
                        const desktopVideo = getImage(settingsHeaderVideos, 'desktop').url
                        headerVideos.desktopUrl = desktopVideo
                    }
                    if (video.size === 'mobile') {
                        const mobileVideo = getImage(settingsHeaderVideos, 'mobile').url
                        headerVideos.mobileUrl = mobileVideo
                    }
                })
            }

            if (settingsData.headerImage.length) {
                const desktopImage = getImage(settingsHeaderImage, 'desktop').url
                const mobileImage = getImage(settingsHeaderImage, 'mobile').url
                headerImages = {
                    desktopUrl: desktopImage,
                    mobileUrl: mobileImage
                }
            }

            return { headerVideos, headerImages }
        }

        settingsData.media = setMediaUrls(settingsData)
        settingsData.isMobile = isMobile
        return {
            props: {
                settings: settingsData,
                meta: {
                    canonical: currentDomainForCors + '/',
                    keywords:
                        'vibee, festivals, travel, clubs, vacations, spring break, destinations, music, events, curated, resorts, experience',
                    url: currentDomainForCors + '/'
                },
                userAgent
            }
        }
    } catch (e) {
        console.error('Error getting server side props for home:', e)
        return {
            notFound: true
        }
    }
}

const scrollElementToTop = (id: string) => {
    let anchorElement = document.getElementById(id)
    if (anchorElement) {
        const elementPosition = anchorElement.getBoundingClientRect().top
        const offset = elementPosition - 80
        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        })
    }
}

const Home: NextPage<any> = (initialAppData) => {
    const [offset, setOffSet] = useState<number>(0)
    const {
        eventId,
        events,
        getData,
        isLoadingEventsAndTours,
        isLoadingMore,
        isPageLoading,
        setEventId,
        setIsPageLoading,
        setTourId,
        tourId,
        tours
    } = useHome(offset, setOffSet)
    const { settings } = initialAppData
    const { headline, isMobile, media, meta } = settings
    const { isMobileWidth } = useWindowWidth()
    const { heroRef, isScrolled } = useNav()
    const isVideo =
        media?.headerVideos?.desktopUrl !== '' || media?.headerVideos?.mobileUrl !== ''
            ? true
            : false

    const noDesktopVideoUrl = media?.headerVideos?.desktopUrl === ''
    const noMobileVideoUrl = media?.headerVideos?.mobileUrl === ''
    const getHeaderMediaUrl = (): string => {
        if (isVideo) {
            if (isMobile) {
                return media.headerVideos.mobileUrl !== ''
                    ? media.headerVideos.mobileUrl
                    : media.headerImages.mobileUrl
            } else {
                return media.headerVideos.desktopUrl !== ''
                    ? media.headerVideos.desktopUrl
                    : media.headerImages.desktopUrl
            }
        } else {
            return isMobile ? media.headerImages.mobileUrl : media.headerImages.desktopUrl
        }
    }

    const renderTourCards = (tours: TourJSON[]): JSX.Element[] => {
        if (tours.length === 0) return []
        return (tours as TourJSON[]).map((tour: TourJSON) => {
            const { id, locationDisplay, slug, name, headerImage }: TourJSON = tour
            const tourLink = `/tours/${slug}`
            let tourLocation: EventJSON | string = ''
            tourLocation = (tour.events as EventJSON[])?.[0]?.location
            const tourLocationDisplay = locationDisplay ? locationDisplay : tourLocation
            const imageUrl: string = getImage(headerImage, 'card').url
            const isLoading: boolean = isPageLoading && tourId === id
            const handleTourCardClick = (): void => {
                setIsPageLoading(true)
                setTourId(id)
            }
            return (
                <Grid
                    key={id}
                    item
                    xl={3}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                >
                    <EventCard
                        chipLabel=""
                        eventDate={'Multiple Dates Available'}
                        eventLocation={tourLocationDisplay}
                        eventName={name}
                        imageUrl={imageUrl}
                        isMobileWidth={isMobileWidth}
                        isLoading={isLoading}
                        key={'experiences_' + id}
                        link={tourLink}
                        onClick={handleTourCardClick}
                        slug={slug}
                    />
                </Grid>
            )
        })
    }

    const renderEventCards = (events: EventJSON[]): JSX.Element[] => {
        if (events.length === 0) return []
        return (events as EventJSON[]).map((event: EventJSON) => {
            const {
                endDate,
                headerImage,
                id,
                name,
                dateDisplay,
                location,
                locationDisplay,
                slug,
                startDate,
                timezone
            }: EventJSON = event
            const eventLink = `/events/${slug}`
            const imageUrl: string = getImage(headerImage, 'card').url
            const isLoading: boolean = isPageLoading && eventId === id
            const eventDate = dateDisplay ? dateDisplay : dateRange(startDate, endDate, timezone)
            const eventLocation = locationDisplay ? locationDisplay : location
            const handleEventCardClick = (): void => {
                setIsPageLoading(true)
                setEventId(id)
            }

            return (
                <Grid
                    key={id}
                    item
                    xl={3}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                >
                    <EventCard
                        chipLabel=""
                        eventDate={eventDate}
                        eventLocation={eventLocation}
                        eventName={name}
                        imageUrl={imageUrl}
                        isLoading={isLoading}
                        isMobileWidth={isMobileWidth}
                        key={'experiences_' + id}
                        link={eventLink}
                        onClick={handleEventCardClick}
                        slug={slug}
                    />
                </Grid>
            )
        })
    }

    const LoadingCards = useMemo(() => {
        const numOfLoadingCards = Array.from({ length: 15 }, (_, index) => index)
        return (
            <>
                {numOfLoadingCards?.map((index: number) => {
                    return (
                        <Grid
                            key={index + 1}
                            item
                            xl={3}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <Skeleton
                                height="300px"
                                variant={SKELETON_VARIANT_RECTANGULAR}
                                sx={{
                                    borderRadius: BORDER_RADIUS,
                                    maxWidth: '411px',
                                    mainWidth: '264px'
                                }}
                            />
                        </Grid>
                    )
                })}
            </>
        )
    }, [])

    useEffect(() => {
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.PAGE_VIEW,
            category: 'Home Page',
            label: window.location.href.split('?')[0],
            pageTitle: 'Home'
        })
    }, [])

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [offset])

    return (
        <>
            <Head>
                <meta
                    name="description"
                    content={meta?.description || 'Vibee'}
                />
                <title>{meta?.title || 'Index'}</title>
            </Head>
            <>
                <HeroContainer
                    headerMediaUrl={getHeaderMediaUrl()}
                    headlineText={headline}
                    heroRef={heroRef}
                    isMobileWidth={isMobileWidth}
                    isVideo={isVideo}
                    isScrolled={isScrolled}
                    scrollElementToTop={scrollElementToTop}
                    showText={(!isMobile && noDesktopVideoUrl) || (isMobile && noMobileVideoUrl)}
                />
                <Container
                    id="events-container"
                    style={{
                        maxWidth: '1800px',
                        paddingTop: SIZE_24,
                        paddingBottom: '56px'
                    }}
                    sx={{
                        [theme.breakpoints.up('md')]: {
                            paddingLeft: '56px',
                            paddingRight: '56px'
                        },
                        [theme.breakpoints.down('md')]: {
                            paddingLeft: SIZE_16,
                            paddingRight: SIZE_16,
                            paddingTop: SIZE_24
                        }
                    }}
                >
                    <div style={{ marginTop: isMobileWidth ? undefined : SIZE_16 }}>
                        <Grid
                            container
                            spacing={SIZE_20}
                        >
                            {isLoadingEventsAndTours
                                ? LoadingCards
                                : [...renderTourCards(tours), ...renderEventCards(events)]}
                            {isLoadingMore && (
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <LoadingMore />
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </Container>
            </>
        </>
    )
}

export default Home
