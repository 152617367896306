import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { SIZE_32 } from 'styles/theme'

export const LoadingMore = () => (
    <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        style={{ padding: SIZE_32, margin: 'auto' }}
    >
        <CircularProgress
            color="secondary"
            size={60}
        />
    </Box>
)
