import Chip from '@mui/material/Chip'
import Skeleton from '@mui/material/Skeleton'
import { FILLED_INPUT_VARIANT, SKELETON_VARIANT_RECTANGULAR } from 'styles/theme'
import { useStyles } from './eventCard.styles'
import Image from 'components/image'

export type EventCardHeroProps = {
    chipLabel?: string
    imageUrl: string
    slug: string
}

export const EventCardHero = ({ chipLabel, imageUrl, slug }: EventCardHeroProps): JSX.Element => {
    const { classes } = useStyles()
    return (
        <div className={classes.eventCardHeroImageContainer}>
            <div className={classes.eventCardImageAndChipWrapper}>
                {imageUrl ? (
                    <>
                        <Image
                            alt={`${slug}-img`}
                            className={classes.eventCardImage}
                            src={imageUrl}
                            sizes="(max-width: 768px) 384px, 384px"
                            layout="fill"
                        />
                        {chipLabel && (
                            <Chip
                                className={classes.eventCardChip}
                                label={chipLabel}
                                variant={FILLED_INPUT_VARIANT}
                            />
                        )}
                    </>
                ) : (
                    <Skeleton variant={SKELETON_VARIANT_RECTANGULAR} />
                )}
            </div>
        </div>
    )
}
