import { useStyles } from './HeroContainer.styles'
import Image from 'components/image'
import styles from './kenBurns.module.css'

type HeroImageProps = {
    headerMediaUrl: string
    isMobileWidth: boolean
    isVideo?: boolean
}

export const HeroImage = ({ headerMediaUrl, isMobileWidth, isVideo }: HeroImageProps) => {
    const { classes } = useStyles()
    return isVideo ? (
        <video
            autoPlay
            controls={false}
            loop
            muted
            playsInline
            poster={headerMediaUrl}
            style={{
                height: '100%',
                left: '50%',
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'auto'
            }}
        >
            <source
                src={headerMediaUrl}
                type="video/mp4"
            />
            <source
                src={headerMediaUrl}
                type="video/webm"
            />
        </video>
    ) : (
        <div className={classes.elementToStretch}>
            <Image
                alt="Vibee header logo image"
                className={`${classes.imgResponsive} ${!isVideo && styles['kb-animation']}`}
                layout="fill"
                priority={true}
                src={headerMediaUrl}
                style={{
                    minHeight: Boolean(isMobileWidth) ? undefined : '500px'
                }}
            />
        </div>
    )
}
