import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { EventCardHero } from './EventCardHero'
import { SIZE_16, SIZE_24, SKELETON_VARIANT_RECTANGULAR } from 'styles/theme'
import { useStyles } from './eventCard.styles'

export type EventCardProps = {
    chipLabel: string
    eventDate: string
    eventLocation: string
    eventName: string
    imageUrl: string
    isMobileWidth: boolean
    isLoading: boolean
    link: string
    onClick: () => void
    slug: string
}

export const EventCard = ({
    chipLabel,
    eventDate,
    eventLocation,
    eventName,
    imageUrl,
    isLoading,
    isMobileWidth,
    link,
    onClick,
    slug
}: EventCardProps): JSX.Element => {
    const { classes } = useStyles()
    return (
        <a
            aria-label={slug}
            href={link}
            style={{
                textDecoration: 'none'
            }}
        >
            <Card
                className={classes.eventCard}
                elevation={0}
            >
                <CardActionArea
                    className={classes.eventCardButton}
                    disableRipple
                    onClick={onClick}
                >
                    {!imageUrl ? (
                        <Skeleton
                            className={classes.eventNameSkeleton}
                            height="100%"
                            variant={SKELETON_VARIANT_RECTANGULAR}
                        />
                    ) : (
                        <EventCardHero
                            chipLabel={chipLabel}
                            imageUrl={imageUrl}
                            slug={slug}
                        />
                    )}
                    <CardContent
                        className={classes.eventCardContent}
                        style={{
                            padding: isMobileWidth ? SIZE_16 : SIZE_24
                        }}
                    >
                        <div className={classes.eventCardContainer}>
                            <div>
                                {!eventName ? (
                                    <Skeleton
                                        className={classes.eventNameSkeleton}
                                        height={30}
                                        variant={SKELETON_VARIANT_RECTANGULAR}
                                    />
                                ) : (
                                    <Typography className={classes.eventName}>
                                        {eventName}
                                    </Typography>
                                )}
                            </div>
                            <div className={classes.eventDateAndLocationContainer}>
                                <div style={{ maxWidth: '80%' }}>
                                    {!eventDate ? (
                                        <Skeleton
                                            variant={SKELETON_VARIANT_RECTANGULAR}
                                            height={20}
                                        />
                                    ) : (
                                        <Typography className={classes.eventDate}>
                                            {eventDate}
                                        </Typography>
                                    )}
                                    {!eventLocation ? (
                                        <Skeleton
                                            variant={SKELETON_VARIANT_RECTANGULAR}
                                            height={20}
                                        />
                                    ) : (
                                        <Typography className={classes.eventLocation}>
                                            {eventLocation}
                                        </Typography>
                                    )}
                                </div>
                                <div style={{ height: '40px', width: '40px' }}>
                                    {isLoading && (
                                        <CircularProgress
                                            color="secondary"
                                            size={40}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        </a>
    )
}
