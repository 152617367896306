import { COLOR_WHITE } from 'styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    elementToStretch: {
        backgroundSize: 'cover',
        height: '100%',
        left: 0,
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        width: '100%'
    },
    imgResponsive: {
        height: '100%',
        objectFit: 'cover',
        width: '100%'
    },
    headlineText: {
        alignItems: 'center',
        color: COLOR_WHITE,
        display: 'flex',
        fontFamily: 'StoutHeavy',
        fontWeight: 900,
        height: '100%',
        justifyContent: 'center',
        margin: 'auto'
    },
    headlineTextContainer: {
        backgroundColor: 'rgba(0,0,0,0.4)',
        height: '100%',
        margin: 'auto',
        position: 'absolute',
        textAlign: 'center',
        top: 0,
        width: '100%'
    },
    heroContainer: {
        alignItems: 'center',
        display: 'flex',
        margin: 'auto',
        position: 'relative',
        width: '100%'
    },
    logoButtonContainer: {
        bottom: '40px',
        height: 'fit-content',
        left: 0,
        margin: 'auto',
        position: 'absolute',
        right: 0,
        textAlign: 'center',
        width: 'fit-content',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
})
