import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useStyles } from './HeroContainer.styles'
import { theme } from 'styles/theme'

type Props = {
    headlineText: string
    isMobileWidth: boolean
}

export const HeroText = ({ headlineText, isMobileWidth }: Props) => {
    const { classes } = useStyles()

    return (
        <>
            {headlineText && (
                <Box className={classes.headlineTextContainer}>
                    <Typography
                        className={classes.headlineText}
                        sx={{
                            fontSize: Boolean(isMobileWidth) ? '2.875rem' : '4rem',
                            fontWeight: 800,
                            lineHeight: '1.1',
                            maxWidth: '550px',
                            [theme.breakpoints.up('xl')]: {
                                fontSize: '4rem',
                                width: '530px'
                            },
                            [theme.breakpoints.down('xl')]: {
                                fontSize: '3.25rem',
                                width: '100%'
                            },
                            [theme.breakpoints.down('lg')]: {
                                width: '450px'
                            },
                            [theme.breakpoints.down('md')]: {
                                fontSize: '2.5rem',
                                width: '430px'
                            },
                            [theme.breakpoints.down('sm')]: {
                                fontSize: '2.5rem',
                                width: '340px'
                            },
                            [theme.breakpoints.up('xs')]: {
                                fontSize: '2.5rem',
                                width: '100%'
                            }
                        }}
                    >
                        {headlineText}
                    </Typography>
                </Box>
            )}
        </>
    )
}
