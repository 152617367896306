import {
    BORDER_RADIUS,
    COLOR_GRAY_100,
    COLOR_GRAY_200,
    COLOR_GRAY_500,
    COLOR_GRAY_800,
    FONT_SIZE_14,
    FONT_SIZE_20,
    SIZE_16,
    SIZE_20,
    SIZE_22,
    SIZE_30,
    SIZE_8
} from '../../styles/theme'
import { makeStyles } from 'tss-react/mui'

// Calculate the aspect ratio of the image (width / height)
const aspectRatio = 411 / 215

export const useStyles = makeStyles()({
    eventCardChip: {
        backgroundColor: COLOR_GRAY_100,
        color: COLOR_GRAY_800,
        height: SIZE_22,
        left: SIZE_16,
        position: 'absolute',
        top: SIZE_16
    },
    eventCard: {
        borderRadius: BORDER_RADIUS,
        border: `1px solid ${COLOR_GRAY_200}`,
        boxShadow: '0px 0px 7px rgba(29, 29, 29, 0.08)',
        height: '100%',
        '&:hover': {
            boxShadow: '0px 0px 16px rgba(29, 29, 29, 0.16)'
        },
        minWidth: '320px'
    },
    eventCardButton: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
        '&:hover': {
            '.MuiCardActionArea-focusHighlight': {
                opacity: 0
            }
        }
    },
    eventCardContent: {
        height: '100%',
        width: '100%'
    },
    eventCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        maxHeight: '156px'
    },
    eventCardHeroImageContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    eventCardImageAndChipWrapper: {
        // Calculate padding-top based on the aspect ratio
        paddingTop: `${(1 / aspectRatio) * 100}%`,
        position: 'relative',
        width: '100%'
    },
    eventCardImage: {
        height: '100%',
        left: 0,
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        width: '100%'
    },
    eventDateAndLocationContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: SIZE_20
    },
    eventDate: {
        color: COLOR_GRAY_500,
        fontSize: FONT_SIZE_14,
        fontWeight: 500,
        lineHeight: SIZE_20,
        marginBottom: SIZE_8
    },
    eventName: {
        color: COLOR_GRAY_800,
        display: '-webkit-box',
        fontSize: FONT_SIZE_20,
        fontWeight: 700,
        lineHeight: SIZE_30,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3
    },
    eventNameSkeleton: {
        marginBottom: SIZE_20
    },
    eventLocation: {
        color: COLOR_GRAY_500,
        flex: 1,
        fontSize: FONT_SIZE_14,
        fontWeight: 500,
        lineHeight: SIZE_20,
        maxHeight: '20px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
})
